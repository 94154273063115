/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import './layout.css';
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import { Footer } from "./Footer"
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

// const primary = {
//   main: "#9032b5",
//   dark: "#66267f",
//   light: "#f6e3ff",
//   contrastText: "#FFFFFF"
// }
let theme = createMuiTheme({
  
});

theme = responsiveFontSizes(theme);

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeTopLayout theme={theme}>
      <Header/>
      <div
        style={{
          margin: `0 auto`,
          // maxWidth: 960,
          // padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main style={{display: 'flex',
          flexFlow: 'column'}}>{children}</main>
        {/* <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Blue Crab Volleyball
          {` `}
          <a href="https://www.gatsbyjs.com">LLC</a>
        </footer> */}
      </div>
      <Footer/>
    </ThemeTopLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
