import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Volleyball from '../assets/Volleyball.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const listItem = {
  paddingLeft: '.5em',
  paddingRight: '.5em',
  boxSizing: 'content-box',
  width: 'auto',
  marginBottom: '0px',
  cursor: 'pointer'
}

const verticalDivider = {
  marginTop: '1.25em',
  marginBottom: '1.25em',
  backgroundColor: 'white'
}

const Header = ({ siteTitle }) => {
  
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <header>
      <AppBar position="static" style={{background: 'linear-gradient(180deg, #243e83, #45bfee94)'}}>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', flexFlow: 'row wrap'}}>
            <div style={{display: 'flex', alignItems: 'center', flex: '1 60%', justifyContent: smallScreen && 'center'}}>
            <Link to="/"><IconButton style={{width: "8vw", minWidth: '100px'}} edge="start" color="inherit" aria-label="menu">
                <Volleyball />
              </IconButton></Link>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{vertical: '700px', horizontal: 1400}}//idk why these dont work
                transformOrigin={{vertical: '700px', horizontal: 1400}}//idk why these dont work
              >
                <MenuItem ><Link to="/about/" activeStyle={{color: '#F78D1F'}}>About Us</Link></MenuItem>
                <MenuItem><Link to="/faq/" activeStyle={{color: '#F78D1F'}}>FAQ</Link></MenuItem>
                <MenuItem><Link to="/covid19/" activeStyle={{color: '#F78D1F'}}>Covid 19</Link></MenuItem>
                <MenuItem><Link to="/schedule/" activeStyle={{color: '#F78D1F'}}>Schedule</Link></MenuItem>
                <MenuItem><Link to="/lessons/" activeStyle={{color: '#F78D1F'}}>Lessons</Link></MenuItem>
                <MenuItem><Link to="/forms/" activeStyle={{color: '#F78D1F'}}>Forms</Link></MenuItem>
                <MenuItem><Link to="/camps/" activeStyle={{color: '#F78D1F'}}>Summer Camps</Link></MenuItem>
              </Menu>
            <Link to="/" style={{color: `white`, textDecoration: `none`}}>
              <Typography variant="h2">Blue Crabs {smallScreen ? <i style={{color: '#F78D1F'}}>VB</i> 
                : <i style={{color: '#F78D1F'}}>Volleyball</i>}</Typography>
            </Link>
            </div>
            <List style={{margin: "0", display: 'flex', flexFlow: 'row', flex: '1', justifyContent: "space-evenly", padding: '0px'}}>
              <ListItem style={{...listItem, marginLeft: smallScreen ? '0' : '2em'}}><Link to="/coaches/" activeStyle={{color: '#F78D1F'}}>Coaches</Link></ListItem>
              <ListItem style={{...listItem}}><Link to="/clinics/" activeStyle={{color: '#F78D1F'}}>Clinics</Link></ListItem>
              <ListItem style={{...listItem}}><Link to="/gallery/" activeStyle={{color: '#F78D1F'}}>Gallery</Link></ListItem>
              <ListItem style={{...listItem}}><Link to="/tournaments/" activeStyle={{color: '#F78D1F'}}>Tournaments</Link></ListItem>
              <ListItem style={{...listItem}}>
                <IconButton color="inherit" aria-label="open menu" onClick={handleOpen} edge="start" style={{padding: '0px'}}>
                  <ExpandMoreIcon />
                </IconButton>
              </ListItem>
            </List>
          </Toolbar>
        </AppBar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
