import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import Grid from '@material-ui/core/Grid';

export const SocialMediaBar = ({orientation, styles}) => {
    return (
        <Grid container direction={orientation} justify={orientation == 'column' ? 'center' : 'space-evenly'} style={{...styles}}>
            <Grid item>
                <a href="https://www.facebook.com/bluecrabsbvb/" target="_blank">
                    <FacebookIcon color="primary"/>
                </a>
            </Grid>
            <Grid item>
                <a href="https://www.instagram.com/bluecrabsbvb/" target="_blank">
                    <InstagramIcon color="primary"/>
                </a>
            </Grid>
            <Grid item>
                <a href="https://www.youtube.com/channel/UC50-7W2BRr8fvXxMbuCEp_g" target="_blank">
                    <YouTubeIcon color="primary"/>
                </a>
            </Grid>
            <Grid item>
                <a href="https://twitter.com/bluecrabsbvb?fbclid=IwAR3m-7OQe1fVw6RbMiTi9QPlg5WJMpmXbWaoou-j85gHk95NyUyORxzqASI" target="_blank">
                    <TwitterIcon color="primary"/>
                </a>
            </Grid>
        </Grid>
    )
}