import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { StaticImage } from "gatsby-plugin-image"
import { SocialMediaBar } from './SocialMediaBar'
import { Link } from "gatsby"
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';//TODO for now not using dividers. 


const footerItem = {
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'column',
    padding: '10px',
}

const footerSubHeading = {
    marginBottom: '15px',
    color: '#243E83'
}

const divider = {
    backgroundColor: '#45BFEE',
    marginLeft: '2vw',
    marginRight: '2vw'
}

export const Footer = () => {
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    
    return(
        <footer>
        <Grid container direction="row" xs={12} justify="space-between" style={{backgroundColor: '#F78D1F'}}>
            <Grid item md={4} sm={6} xs={12} style={{...footerItem, order: '1'}}>
                <Typography variant="subtitle1" style={{...footerSubHeading}}>Quick Links </Typography>
                <Grid container direction="row" style={{fontSize: 'initial', alignItems: 'center', flex: '1', color: '#243E83'}}>
                    <Grid container direction="column" justify="space-evenly" item xs={6} style={{aligntText: 'center', height: '100%'}}>
                        <Link to="/faq/">FAQ</Link>
                        <Link to="/about/">About Us</Link>
                        <Link to="/forms/">Forms</Link>
                        <Link to="/covid19/">Covid 19</Link>
                    </Grid>
                    <Grid container direction="column" justify="space-evenly" item xs={6} style={{aligntText: 'center', height: '100%'}}>
                        <Link to="/lessons/">Private Lessons</Link>
                        <Link to="/personal_training/">Personal Training</Link>
                        <Link to="/camps/">Summer Camps</Link>
                        <Link to="/calendar/">Event Calendar</Link>
                    </Grid>
                   
                </Grid>
            </Grid>
            <Grid item md={4} xs={12} style={{...footerItem, order: smScreen ? '3' : '2'}}>
                <Typography variant="subtitle1" style={{...footerSubHeading}}>Proud Member Of</Typography>
                <Grid container direction="row" justify="space-evenly" alignItems="center" style={{flex: '1'}}>
                    <a href="https://usavolleyball.org/" target="_blank"><StaticImage  layout="constrained" height={90}  src={"../images/usav_logo.png"} alt={"testing"} /></a>
                    <a href="https://www.chrva.org/" target="_blank"><StaticImage  layout="constrained" height={90} src={"../images/chrva_logo.png"} alt={"testing"} /></a>
                    <a href="https://www.avca.org/" target="_blank"><StaticImage  layout="constrained" height={90} src={"../images/avca_logo1.png"} alt={"testing"} /></a>
                </Grid>
            </Grid>
            <Grid item md={4} sm={6} xs={12} container direction="row" wrap="wrap" justify="center" style={{order: smScreen ? '2' : '3', padding: '10px'}}>
                <Grid item md={12}>
                    <Typography variant="subtitle1" style={{...footerSubHeading, textAlign: 'center'}}>Stay Connected</Typography>
                </Grid>
                <Grid item lg={8} md={10} sm={10} style={{fontSize: 'initial'}} container direction="column" justify="space-evenly">
                    <Container style={{display: 'flex', justifyContent: xsScreen && 'center', paddingBottom: '5px'}}>
                        <BusinessIcon color="primary" style={{marginRight: '5px'}}/>
                        <address style={{marginBottom: '0'}}>
                        8300 Greensboro Drive
                        McLean, VA,
                        22102 - #L1-677
                        </address>
                    </Container>
                    <Container style={{display: 'flex', justifyContent: xsScreen && 'center', paddingBottom: '5px'}}>
                        <EmailTwoToneIcon color="primary" style={{marginRight: '5px'}}/><a href='mailto:contact@bluecrabsvolleyball.com'>contact@bluecrabsvolleyball.com</a>
                    </Container>
                    <Container style={{display: 'flex', justifyContent: xsScreen && 'center', paddingBottom: '5px'}}>
                        <PhoneOutlinedIcon color="primary" style={{marginRight: '5px'}}/><a href="tel:5714820147">571-482-0147</a>
                    </Container>
                </Grid>
                <Grid item md={12} sm={2} lg={2} xs={12} style={{display: 'flex'}}>
                    <SocialMediaBar orientation={useMediaQuery(theme.breakpoints.only('md')) || xsScreen ? "row" : "column"}
                        styles={{alignItems: mdScreen && 'center'}}/>
                </Grid>
            </Grid>
        </Grid>
        <Container maxWidth="false" style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#243E83'}}>
            <Typography variant="small"><small style={{color: 'white'}}>&copy;{new Date().getFullYear()}, Webfordable LLC. All Rights Reserved.</small></Typography>
            <Link to="#"><Typography variant="caption" style={{color: 'white', textDecoration: 'underline'}}>{xsScreen ? 'T.O.S' : 'Terms of Service'}</Typography></Link>
        </Container>
        </footer>
    )
}